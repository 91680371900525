export default function Footer({
  children,
  fluid = false,
  className = "",
  ...props
}: {
  children?: React.ReactNode;
  fluid?: boolean;
  className?: string;
  [key: string]: any;
}) {
  return (
    <footer className={`mt-auto bg-base-300 ${className}`} {...props}>
      {children}
    </footer>
  );
}
