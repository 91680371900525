"use client";

import { faTwitter, faVk, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faMicroscope } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";

import { useSession } from "../../components/session";
import { HeaderMenu } from "../header-content";
import Footer from "../footer";

function Hero({ ...props })
{
  const { user } = useSession();
  const pathname = usePathname();

  return (
    <div className="bg-[url('/assets/back.png')] bg-no-repeat bg-cover flex flex-col flex-grow ">
      <div className="px-5 pt-5">
        <h1 className="fw-bold text-4xl">SEM Analyzer</h1>
        <div className="flex w-10/12">
          <div className="w-2/3">
            <p className="mb-4 mt-3 text-xl">
              Introducing our advanced online platform for SEM image processing and analysis, specifically crafted to cater to the demands of petrology, geology, and mineralogy. This all-encompassing solution supports a diverse range of microscope models from multiple manufacturers, offering maximum compatibility and adaptability. With a comprehensive array of features for image preprocessing, processing, and analysis, our tool streamlines the transition from manual workflows to fully automated processes, delivering a significant boost in both accuracy and efficiency for your research and industrial projects.
            </p>
            <div className="flex gap-2 flex-col justify-around mb-4">
              <Link href="/projects" className="daisy-btn daisy-btn-ghost w-1/2 border border-gray-600 rounded-full shadow-lg">
                Try Alpha Version
              </Link>
              <Link href="/docs" className="daisy-btn daisy-btn-ghost w-1/2 border border-gray-600 rounded-full shadow-lg">
                Documentation
              </Link>
            </div>
            <div className="mt-10 flex gap-2 flex-col w-1/3">
              <Link
                role="button"
                href={"/auth/login" + (pathname.startsWith("/auth") ? "" : "?to=" + pathname)}
                className={clsx("daisy-btn daisy-btn-ghost border border-gray-600 rounded-full shadow-lg", {
                  "daisy-btn-disabled": pathname.startsWith("/auth/login"),
                })}
              >
                Log In
              </Link>
              <Link
                role="button"
                href="/auth/signup"
                className={clsx("daisy-btn daisy-btn-ghost border border-gray-600 rounded-full shadow-lg", {
                  "daisy-btn-disabled": pathname.startsWith("/auth/signup"),
                })}
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div className="container relative flex flex-row gap-10 mx-10">
            <Image
              src="/assets/screenshot1.png"
              className="rounded-3xl border border-gray-400 shadow-lg h-[30vh] w-[30vh]"
              alt="SEM Analyzer (screenshot)"
              loading="lazy"
              width={700}
              height={500}
            />
            <Image
              src="/assets/screenshot2.png"
              className="rounded-3xl border border-gray-400 shadow-lg h-[30vh] w-[30vh]"
              alt="SEM Analyzer (screenshot)"
              loading="lazy"
              width={700}
              height={500}
            />
            <Image
              src="/assets/screenshot3.png"
              className="absolute top-0 left-40 top-60 rounded-3xl border border-gray-400 shadow-lg h-[30vh] w-[30vh]"
              alt="SEM Analyzer (screenshot)"
              loading="lazy"
              width={700}
              height={500}
            />
          </div>
        </div>
      </div>

    </div>
  );
}

function Features({ ...props })
{
  const features = [
    {
      id: 1,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
    {
      id: 2,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
    {
      id: 3,
      icon: faMicroscope,
      title: "Featured title",
      description:
        "Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.",
      button: "Primary button",
      href: "#",
    },
  ];

  return (
    <div className="bg-neutral text-neutral-content" {...props}>
      <div className="container mx-auto flex flex-col gap-4 px-3 py-4 sm:flex-row sm:gap-6 sm:px-4 sm:py-5 lg:gap-8">
        {features.map((feature) => (
          <div key={feature.id} className="flex flex-col items-center lg:flex-row lg:items-start">
            <FontAwesomeIcon icon={feature.icon} size="2x" className="lg:me-4" />
            <div>
              <h3 className="text-2xl font-bold">{feature.title}</h3>
              <p className="py-2">{feature.description}</p>
              <a href={feature.href} className="daisy-btn daisy-btn-primary daisy-btn-sm">
                {feature.button}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Pricing({ ...props })
{
  return (
    <div className="container mx-auto flex flex-col gap-8 px-3 py-4 sm:flex-row">
      <div className="daisy-card flex-1 overflow-hidden border bg-base-100 shadow-xl">
        <h5 className="border-b bg-base-200 p-3 text-center">Free!</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $0<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>10 users included</li>
              <li>2 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Sign up for free</button>
        </div>
      </div>

      <div className="daisy-card flex-1 overflow-hidden border bg-base-100 shadow-xl">
        <h5 className="border-b bg-base-200 p-3 text-center">Pro</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $5<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>20 users included</li>
              <li>10 GB of storage</li>
              <li>Priority email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Get Started</button>
        </div>
      </div>

      <div className="daisy-card flex-1 overflow-hidden border border-primary bg-base-100 shadow-xl">
        <h5 className="border-b bg-primary p-3 text-center text-primary-content">Enterprise</h5>
        <div className="daisy-card-body p-3">
          <div className="text-center">
            <span>
              $10<small className="opacity-80">/mo</small>
            </span>
            <ul className="my-3">
              <li>30 users included</li>
              <li>15 GB of storage</li>
              <li>Phone and email support</li>
              <li>Help center access</li>
            </ul>
          </div>
          <button className="daisy-btn daisy-btn-outline daisy-btn-primary daisy-btn-block">Contact us</button>
        </div>
      </div>
    </div>
  );
}

export default function Page()
{
  return (
    <>
      <Hero id="home" />
      <Footer className="">
        <FooterContent />
      </Footer>
    </>
  );
}


function FooterContent(): JSX.Element
{
  const copyright = "© " + (new Date()).getFullYear() + " Company, Inc";

  return (
    <div className="container mx-auto py-3">
      <div className="flex justify-between align-items-center py-3">
        <div className="flex">
          <img alt="Sudo LMCC" src="https://sudo.team/build/assets/logo-5570c104.svg" className="logo" />
        </div>
        <div className="flex items-center">
          <span className="text-base-content" id="year">{copyright}</span>
        </div>
      </div>
    </div>
  );
}


